export const GET_LIST_PRODUK = "GET_LIST_PRODUK";
export const GET_LIST_PRODUK_SUCCESS = "GET_LIST_PRODUK_SUCCESS";
export const GET_LIST_PRODUK_FAILED = "GET_LIST_PRODUK_FAILED";
export const GET_TOTAL_LIST_PRODUK_SUCCESS = "GET_TOTAL_LIST_PRODUK_SUCCESS";
export const GET_TOTAL_LIST_PRODUK_FAILED = "GET_TOTAL_LIST_PRODUK_FAILED";
export const SYNC_PRODUCT_SUCCESS = "SYNC_PRODUCT_SUCCESS";
export const SYNC_PRODUCT_FAILED = "SYNC_PRODUCT_FAILED";
export const DELETE_PRODUK_SUCCESS = "DELETE_PRODUK_SUCCESS";
export const DELETE_PRODUK_FAILED = "DELETE_PRODUK_FAILED";
export const SET_STATUS_PRODUK_SUCCESS = "SET_STATUS_PRODUK_SUCCESS";
export const SET_STATUS_PRODUK_FAILED = "SET_STATUS_PRODUK_FAILED";
export const GET_LIST_SET_PRODUK = "GET_LIST_SET_PRODUK";
export const GET_LIST_SET_PRODUK_SUCCESS = "GET_LIST_SET_PRODUK_SUCCESS";
export const GET_LIST_SET_PRODUK_FAILED = "GET_LIST_SET_PRODUK_FAILED";
export const SYNC_SET_PRODUCT_SUCCESS = "SYNC_SET_PRODUCT_SUCCESS";
export const SYNC_SET_PRODUCT_FAILED = "SYNC_SET_PRODUCT_FAILED";
export const GET_TOTAL_LIST_SET_PRODUK_SUCCESS =
  "GET_TOTAL_LIST_SET_PRODUK_SUCCESS";
export const GET_TOTAL_LIST_SET_PRODUK_FAILED =
  "GET_TOTAL_LIST_SET_PRODUK_FAILED";
export const SET_FAILED_EDIT_KODE_INADD_SUCCESS =
  "SET_FAILED_EDIT_KODE_INADD_SUCCESS";
export const SET_FAILED_EDIT_KODE_INADD_FAILED =
  "SET_FAILED_EDIT_KODE_INADD_FAILED";
export const SET_FAILED_EDIT_KODE_INDETAIL_SUCCESS =
  "SET_FAILED_EDIT_KODE_INDETAIL_SUCCESS";
export const SET_FAILED_EDIT_KODE_INDETAIL_FAILED =
  "SET_FAILED_EDIT_KODE_INDETAIL_FAILED";
export const GET_PRODUK_EDIT_DATA = "GET_PRODUK_EDIT_DATA";
export const GET_PRODUK_EDIT_DATA_SUCCESS = "GET_PRODUK_EDIT_DATA_SUCCESS";
export const GET_PRODUK_EDIT_DATA_FAILED = "GET_PRODUK_EDIT_DATA_FAILED";
export const SET_PRODUK_EDIT = "SET_PRODUK_EDIT";
export const ADD_PRODUK_SUCCESS = "ADD_PRODUK_SUCCESS";
export const ADD_PRODUK_FAILED = "ADD_PRODUK_FAILED";
export const ADD_PRODUK_MARGIN_SUCCESS = "ADD_PRODUK_MARGIN_SUCCESS";
export const ADD_PRODUK_MARGIN_FAILED = "ADD_PRODUK_MARGIN_FAILED";
export const GET_SPLASH_SCREEN = "GET_SPLASH_SCREEN";
export const GET_SPLASH_SCREEN_SUCCESS = "GET_SPLASH_SCREEN_SUCCESS";
export const GET_SPLASH_SCREEN_FAILED = "GET_SPLASH_SCREEN_FAILED";
export const GET_MASTER_PRODUK = "GET_MASTER_PRODUK";
export const GET_MASTER_PRODUK_SUCCESS = "GET_MASTER_PRODUK_SUCCESS";
export const GET_MASTER_PRODUK_FAILED = "GET_MASTER_PRODUK_FAILED";
export const GET_MASTER_PROVIDER = "GET_MASTER_PROVIDER";
export const GET_MASTER_PROVIDER_SUCCESS = "GET_MASTER_PROVIDER_SUCCESS";
export const GET_MASTER_PROVIDER_FAILED = "GET_MASTER_PROVIDER_FAILED";
export const GET_MASTER_TIPE = "GET_MASTER_TIPE";
export const GET_MASTER_TIPE_SUCCESS = "GET_MASTER_TIPE_SUCCESS";
export const GET_MASTER_TIPE_FAILED = "GET_MASTER_TIPE_FAILED";
export const GET_MASTER_SUBTIPE = "GET_MASTER_SUBTIPE";
export const GET_MASTER_SUBTIPE_SUCCESS = "GET_MASTER_SUBTIPE_SUCCESS";
export const GET_MASTER_SUBTIPE_FAILED = "GET_MASTER_SUBTIPE_FAILED";
export const GET_MASTER_KATEGORI = "GET_MASTER_KATEGORI";
export const GET_MASTER_KATEGORI_SUCCESS = "GET_MASTER_KATEGORI_SUCCESS";
export const GET_MASTER_KATEGORI_FAILED = "GET_MASTER_KATEGORI_FAILED";
export const GET_SUPPLIER_PRODUK_SUCCESS = "GET_SUPPLIER_PRODUK_SUCCESS";
export const GET_SUPPLIER_PRODUK_FAILED = "GET_SUPPLIER_PRODUK_FAILED";
export const GET_SUPPLIER_PROVIDER_SUCCESS = "GET_SUPPLIER_PROVIDER_SUCCESS";
export const GET_SUPPLIER_PROVIDER_FAILED = "GET_SUPPLIER_PROVIDER_FAILED";
export const SEARCH_SETDATA_FAILED = "SEARCH_SETDATA_FAILED";
export const SET_PREMIUM_MASTER_PRODUK_SUCCESS =
  "SET_PREMIUM_MASTER_PRODUK_SUCCESS";
export const SET_PREMIUM_MASTER_PRODUK_FAILED =
  "SET_PREMIUM_MASTER_PRODUK_FAILED";
export const ADD_MASTER_PROVIDER_SUCCESS = "ADD_MASTER_PROVIDER_SUCCESS";
export const ADD_MASTER_PROVIDER_FAILED = "ADD_MASTER_PROVIDER_FAILED";
export const ADD_MASTER_TIPE_SUCCESS = "ADD_MASTER_TIPE_SUCCESS";
export const ADD_MASTER_TIPE_FAILED = "ADD_MASTER_TIPE_FAILED";
export const ADD_MASTER_SUBTIPE_SUCCESS = "ADD_MASTER_SUBTIPE_SUCCESS";
export const ADD_MASTER_SUBTIPE_FAILED = "ADD_MASTER_SUBTIPE_FAILED";
export const ADD_MASTER_KATEGORI_SUCCESS = "ADD_MASTER_KATEGORI_SUCCESS";
export const ADD_MASTER_KATEGORI_FAILED = "ADD_MASTER_KATEGORI_FAILED";
export const GET_SET_PRODUK_BY_CODE_SUCCESS = "GET_SET_PRODUK_BY_CODE_SUCCESS";
export const GET_SET_PRODUK_BY_CODE_FAILED = "GET_SET_PRODUK_BY_CODE_FAILED";
export const FORM_PRODUK_ADD_DATA = "FORM_PRODUK_ADD_DATA";
export const FORM_PRODUK_EDIT_DATA_SUCCESS = "FORM_PRODUK_EDIT_DATA_SUCCESS";
export const FORM_PRODUK_EDIT_DATA_FAILED = "FORM_PRODUK_EDIT_DATA_FAILED";
export const FORM_PROVIDER_ADD_DATA = "FORM_PROVIDER_ADD_DATA";
export const FORM_PROVIDER_EDIT_DATA_SUCCESS =
  "FORM_PROVIDER_EDIT_DATA_SUCCESS";
export const FORM_PROVIDER_EDIT_DATA_FAILED = "FORM_PROVIDER_EDIT_DATA_FAILED";
export const FORM_TIPE_ADD_DATA = "FORM_TIPE_ADD_DATA";
export const FORM_TIPE_EDIT_DATA_SUCCESS = "FORM_TIPE_EDIT_DATA_SUCCESS";
export const FORM_TIPE_EDIT_DATA_FAILED = "FORM_TIPE_EDIT_DATA_FAILED";
export const FORM_SUBTIPE_ADD_DATA = "FORM_SUBTIPE_ADD_DATA";
export const FORM_SUBTIPE_EDIT_DATA_SUCCESS = "FORM_SUBTIPE_EDIT_DATA_SUCCESS";
export const FORM_SUBTIPE_EDIT_DATA_FAILED = "FORM_SUBTIPE_EDIT_DATA_FAILED";
export const FORM_KATEGORI_ADD_DATA = "FORM_KATEGORI_ADD_DATA";
export const FORM_KATEGORI_EDIT_DATA_SUCCESS =
  "FORM_KATEGORI_EDIT_DATA_SUCCESS";
export const FORM_KATEGORI_EDIT_DATA_FAILED = "FORM_KATEGORI_EDIT_DATA_FAILED";
export const UPDATE_MASTER_PROVIDER_SUCCESS = "UPDATE_MASTER_PROVIDER_SUCCESS";
export const UPDATE_MASTER_PROVIDER_FAILED = "UPDATE_MASTER_PROVIDER_FAILED";
export const UPDATE_MASTER_TIPE_SUCCESS = "UPDATE_MASTER_TIPE_SUCCESS";
export const UPDATE_MASTER_TIPE_FAILED = "UPDATE_MASTER_TIPE_FAILED";
export const UPDATE_MASTER_SUBTIPE_SUCCESS = "UPDATE_MASTER_SUBTIPE_SUCCESS";
export const UPDATE_MASTER_SUBTIPE_FAILED = "UPDATE_MASTER_SUBTIPE_FAILED";
export const UPDATE_MASTER_KATEGORI_SUCCESS = "UPDATE_MASTER_KATEGORI_SUCCESS";
export const UPDATE_MASTER_KATEGORI_FAILED = "UPDATE_MASTER_KATEGORI_FAILED";
export const DELETE_MASTER_PROVIDER_SUCCESS = "DELETE_MASTER_PROVIDER_SUCCESS";
export const DELETE_MASTER_PROVIDER_FAILED = "DELETE_MASTER_PROVIDER_FAILED";
export const DELETE_MASTER_TIPE_SUCCESS = "DELETE_MASTER_TIPE_SUCCESS";
export const DELETE_MASTER_TIPE_FAILED = "DELETE_MASTER_TIPE_FAILED";
export const DELETE_MASTER_SUBTIPE_SUCCESS = "DELETE_MASTER_SUBTIPE_SUCCESS";
export const DELETE_MASTER_SUBTIPE_FAILED = "DELETE_MASTER_SUBTIPE_FAILED";
export const DELETE_MASTER_KATEGORI_SUCCESS = "DELETE_MASTER_KATEGORI_SUCCESS";
export const DELETE_MASTER_KATEGORI_FAILED = "DELETE_MASTER_KATEGORI_FAILED";
export const SET_MAINTENANCE_PRODUK_SUCCESS = "SET_MAINTENANCE_PRODUK_SUCCESS";
export const SET_MAINTENANCE_PRODUK_FAILED = "SET_MAINTENANCE_PRODUK_FAILED";
export const GET_MAINTENANCE_PRODUK_SUCCESS = "GET_MAINTENANCE_PRODUK_SUCCESS";
export const GET_MAINTENANCE_PRODUK_FAILED = "GET_MAINTENANCE_PRODUK_FAILED";
export const GET_MASTER_SUPPLIER_V2 = "GET_MASTER_SUPPLIER_V2";
export const GET_MASTER_SUPPLIER_V2_SUCCESS = "GET_MASTER_SUPPLIER_V2_SUCCESS";
export const GET_MASTER_SUPPLIER_V2_FAILED = "GET_MASTER_SUPPLIER_V2_FAILED";
export const GET_MASTER_PRODUK_V2 = "GET_MASTER_PRODUK_V2";
export const GET_MASTER_PRODUK_V2_SUCCESS = "GET_MASTER_PRODUK_V2_SUCCESS";
export const GET_MASTER_PRODUK_V2_FAILED = "GET_MASTER_PRODUK_V2_FAILED";
export const GET_MASTER_PRODUK_TYPE_V2 = "GET_MASTER_PRODUK_TYPE_V2";
export const GET_MASTER_PRODUK_TYPE_V2_SUCCESS =
  "GET_MASTER_PRODUK_TYPE_V2_SUCCESS";
export const GET_MASTER_PRODUK_TYPE_V2_FAILED =
  "GET_MASTER_PRODUK_TYPE_V2_FAILED";
export const GET_MASTER_PRODUK_TYPE_DETAIL_V2 =
  "GET_MASTER_PRODUK_TYPE_DETAIL_V2";
export const GET_MASTER_PRODUK_TYPE_DETAIL_V2_SUCCESS =
  "GET_MASTER_PRODUK_TYPE_DETAIL_V2_SUCCESS";
export const GET_MASTER_PRODUK_TYPE_DETAIL_V2_FAILED =
  "GET_MASTER_PRODUK_TYPE_DETAIL_V2_FAILED";
export const GET_MASTER_PRODUK_PROVIDER_V2 = "GET_MASTER_PRODUK_PROVIDER_V2";
export const GET_MASTER_PRODUK_PROVIDER_V2_SUCCESS =
  "GET_MASTER_PRODUK_PROVIDER_V2_SUCCESS";
export const GET_MASTER_PRODUK_PROVIDER_V2_FAILED =
  "GET_MASTER_PRODUK_PROVIDER_V2_FAILED";
export const GET_MASTER_PRODUK_CATEGORY_V2 = "GET_MASTER_PRODUK_CATEGORY_V2";
export const GET_MASTER_PRODUK_CATEGORY_V2_SUCCESS =
  "GET_MASTER_PRODUK_CATEGORY_V2_SUCCESS";
export const GET_MASTER_PRODUK_CATEGORY_V2_FAILED =
  "GET_MASTER_PRODUK_CATEGORY_V2_FAILED";
export const ADD_PRODUCT_DETAIL = "ADD_PRODUCT_DETAIL";
export const ADD_PRODUCT_DETAIL_SUCCESS = "ADD_PRODUCT_DETAIL_SUCCESS";
export const ADD_PRODUCT_DETAIL_FAILED = "ADD_PRODUCT_DETAIL_FAILED";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_SEARCH_TEXT_ENTER = "SET_SEARCH_TEXT_ENTER";
export const SET_SEARCH_TEXT_OPTION = "SET_SEARCH_TEXT_OPTION";
