import { Layout } from "antd";
import HeaderEmpty from "./HeaderAction/HeaderEmpty";
import HeaderFeedback from "./HeaderAction/HeaderFeedback";
import HeaderFlashsale from "./HeaderAction/HeaderFlashsale";
import HeaderInfoProduk from "./HeaderAction/HeaderInfoProduk";
import HeaderProduk from "./HeaderAction/HeaderProduk";
import HeaderSetMetode from "./HeaderAction/HeaderSetMetode";
import HeaderSetOTP from "./HeaderAction/HeaderSetOTP";
import HeaderTransaksi from "./HeaderAction/HeaderTransaksi";
import HeaderSalahTransfer from "./HeaderAction/HeaderSalahTransfer";
import HeaderPelanggan from "./HeaderAction/HeaderPelanggan";
import HeaderPengguna from "./HeaderAction/HeaderPengguna";
import HeaderSpesial from "./HeaderAction/HeaderSpesial";
import HeaderKupon from "./HeaderAction/HeaderKupon";
import HeaderPemberitahuan from "./HeaderAction/HeaderPemberitahuan";
import HeaderMenarik from "./HeaderAction/HeaderMenarik";
import HeaderBlacklist from "./HeaderAction/HeaderBlacklist";
import HeaderSplashScreen from "./HeaderAction/HeaderSplashScreen";

const HeaderForm = (props) => {
  const url = window.location.href.split("/");
  const customHeader = url[4];
  const submenu = url[5];
  const { Header } = Layout;

  const header_style = {
    position: "fixed",
    padding: 0,
    zIndex: 999,
  };
  return (
    <Header className="site-layout-sub-header-background" style={header_style}>
      <div className="wrapAltHeader">
        {customHeader === "tambahproduk" ||
        customHeader === "detailproduk" ||
        customHeader === "tambah_produk_detail" ? (
          <HeaderProduk
            menu={customHeader}
            form={props.formProduk}
            loading={props.loading}
          />
        ) : customHeader === "detailsetmetode" ? (
          <HeaderSetMetode
            form={props.formDepositMetode}
            loading={props.loading}
          />
        ) : customHeader === "promo" ? (
          submenu === "tambahflashsale" || submenu === "detailflashsale" ? (
            <HeaderFlashsale
              menu={submenu}
              code={url[6]}
              form={props.formPromoFlashsale}
              loading={props.loading}
            />
          ) : submenu === "tambahspesial" || submenu === "detailspesial" ? (
            <HeaderSpesial
              menu={submenu}
              code={url[6]}
              form={props.formPromoSpesial}
              loading={props.loading}
            />
          ) : submenu === "tambahkupon" || submenu === "detailkupon" ? (
            <HeaderKupon
              menu={submenu}
              code={url[6]}
              form={props.formPromoKupon}
              loading={props.loading}
            />
          ) : (
            <></>
          )
        ) : customHeader === "informasi" ? (
          submenu === "tambahpemberitahuan" ||
          submenu === "detailpemberitahuan" ? (
            <HeaderPemberitahuan
              menu={submenu}
              code={url[6]}
              form={props.formInformasiPemberitahuan}
              loading={props.loading}
            />
          ) : submenu === "tambahmenarik" || submenu === "detailmenarik" ? (
            <HeaderMenarik
              menu={submenu}
              code={url[6]}
              form={props.formInformasiMenarik}
              loading={props.loading}
            />
          ) : submenu === "tambahproduk" || submenu === "detailproduk" ? (
            <HeaderInfoProduk
              menu={submenu}
              form={props.formInformasiProduk}
              loading={props.loading}
            />
          ) : submenu === "tambahsplashscreen" ||
            submenu === "detailsplashscreen" ? (
            <HeaderSplashScreen
              menu={submenu}
              code={url[6]}
              form={props.formSplashScreen}
              loading={props.loading}
            />
          ) : (
            <></>
          )
        ) : customHeader === "detailpelanggan" ? (
          <HeaderPelanggan menu={customHeader} loading={props.loading} />
        ) : customHeader === "transaksi" ? (
          submenu === "detailproduk" || submenu === "detaildeposit" ? (
            <HeaderTransaksi
              menu={submenu}
              trx_id={url[6]}
              totalDeposit={props.totalDeposit}
            />
          ) : submenu === "detailpengecekan" ? (
            <HeaderEmpty menu={submenu} />
          ) : submenu === "salahtransfer" ? (
            <HeaderSalahTransfer
              menu={submenu}
              trx_id={url[6]}
              form={props.formSalahTransfer}
              loading={props.loading}
            />
          ) : (
            <></>
          )
        ) : customHeader === "detailfeedback" ? (
          <HeaderFeedback menu={customHeader} fb_id={submenu} />
        ) : customHeader === "tambahblacklist" ? (
          <HeaderBlacklist
            menu={customHeader}
            form={props.formTambahBlacklist}
            loading={props.loading}
          />
        ) : customHeader === "setotp" ? (
          submenu === "tambahprovider" || submenu === "detailprovider" ? (
            <HeaderSetOTP
              menu={submenu}
              form={props.formIntegrasiOTP}
              loading={props.loading}
            />
          ) : (
            <></>
          )
        ) : customHeader === "tambah_pengguna" ||
          customHeader === "detail_pengguna" ? (
          <HeaderPengguna
            menu={customHeader}
            form={props.formTambahPengguna}
            id={submenu}
          />
        ) : customHeader === "tambah_jabatan" ||
          customHeader === "custom_jabatan" ||
          customHeader === "detail_jabatan" ? (
          <HeaderPengguna
            menu={customHeader}
            form={props.formJabatan}
            id={submenu}
          />
        ) : customHeader === "tambah_parent" ||
          customHeader === "detail_parent" ? (
          <HeaderPengguna
            menu={customHeader}
            form={props.formParent}
            id={submenu}
          />
        ) : customHeader === "tambah_menu" || customHeader === "detail_menu" ? (
          <HeaderPengguna
            menu={customHeader}
            form={props.formMenu}
            id={submenu}
          />
        ) : customHeader === "tambah_fitur" ||
          customHeader === "detail_fitur" ? (
          <HeaderPengguna
            menu={customHeader}
            form={props.formFitur}
            id={submenu}
          />
        ) : (
          <></>
        )}
      </div>
    </Header>
  );
};

export default HeaderForm;
