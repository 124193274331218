import baseAction from "../../utils/baseAction";
import basePromiseAction from "../../utils/basePromiseAction";
import {
  GET_TRANSAKSI_PRODUK_SUCCESS,
  GET_TRANSAKSI_PRODUK_FAILED,
  GET_TRANSAKSI_DEPOSIT_SUCCESS,
  GET_TRANSAKSI_DEPOSIT_FAILED,
  GET_TRANSAKSI_PENGECEKAN_SUCCESS,
  GET_TRANSAKSI_PENGECEKAN_FAILED,
  GET_TOTAL_TRANSAKSI_PRODUK_SUCCESS,
  GET_TOTAL_TRANSAKSI_PRODUK_FAILED,
  GET_TOTAL_TRANSAKSI_DEPOSIT_SUCCESS,
  GET_TOTAL_TRANSAKSI_DEPOSIT_FAILED,
  GET_TOTAL_TRANSAKSI_PENGECEKAN_SUCCESS,
  GET_TOTAL_TRANSAKSI_PENGECEKAN_FAILED,
  GET_TRANSAKSI_PRODUK,
  GET_TRANSAKSI_DEPOSIT,
  GET_TRANSAKSI_PENGECEKAN,
  GET_DETAIL_TRANSAKSI_PRODUK,
  GET_DETAIL_TRANSAKSI_PRODUK_SUCCESS,
  GET_DETAIL_TRANSAKSI_PRODUK_FAILED,
  CHANGE_REFERENSI_TRANSAKSI_PRODUK_SUCCESS,
  CHANGE_REFERENSI_TRANSAKSI_PRODUK_FAILED,
  GET_DETAIL_TRANSAKSI_DEPOSIT,
  GET_DETAIL_TRANSAKSI_DEPOSIT_SUCCESS,
  GET_DETAIL_TRANSAKSI_DEPOSIT_FAILED,
  CHANGE_TOTAL_TRANSAKSI_DEPOSIT_SUCCESS,
  CHANGE_TOTAL_TRANSAKSI_DEPOSIT_FAILED,
  GET_DETAIL_TRANSAKSI_PENGECEKAN,
  GET_DETAIL_TRANSAKSI_PENGECEKAN_SUCCESS,
  GET_DETAIL_TRANSAKSI_PENGECEKAN_FAILED,
  SUKSESKAN_TRANSAKSI_PRODUK_SUCCESS,
  SUKSESKAN_TRANSAKSI_PRODUK_FAILED,
  GAGALKAN_TRANSAKSI_PRODUK_SUCCESS,
  GAGALKAN_TRANSAKSI_PRODUK_FAILED,
  SUKSESKAN_TRANSAKSI_DEPOSIT_SUCCESS,
  SUKSESKAN_TRANSAKSI_DEPOSIT_FAILED,
  GAGALKAN_TRANSAKSI_DEPOSIT_SUCCESS,
  GAGALKAN_TRANSAKSI_DEPOSIT_FAILED,
  GET_LIST_FEEDBACK,
  GET_LIST_FEEDBACK_SUCCESS,
  GET_LIST_FEEDBACK_FAILED,
  GET_DETAIL_FEEDBACK,
  GET_DETAIL_FEEDBACK_SUCCESS,
  GET_DETAIL_FEEDBACK_FAILED,
  DELETE_FEEDBACK_SUCCESS,
  DELETE_FEEDBACK_FAILED,
  BACKLOG_FEEDBACK_SUCCESS,
  BACKLOG_FEEDBACK_FAILED,
  CANCEL_BACKLOG_FEEDBACK_SUCCESS,
  CANCEL_BACKLOG_FEEDBACK_FAILED,
  GET_LIST_KATEGORI_FEEDBACK_SUCCESS,
  GET_LIST_KATEGORI_FEEDBACK_FAILED,
  GET_METODE_DEPOSIT_SUCCESS,
  GET_METODE_DEPOSIT_FAILED,
  GET_STATUS_NOBU_SUCCESS,
  GET_STATUS_NOBU_FAILED,
  GET_MUTASI_SALAH_TRANSFER_SUCCESS,
  GET_MUTASI_SALAH_TRANSFER_FAILED,
  GET_MUTASI_SALAH_TRANSFER,
  SUKSESKAN_SALAH_TRANSFER_SUCCESS,
  SUKSESKAN_SALAH_TRANSFER_FAILED,
  SUKSESKAN_SALAH_TRANSFER,
  CHANGE_KETERANGAN_TRANSAKSI_PRODUK_SUCCESS,
  CHANGE_KETERANGAN_TRANSAKSI_PRODUK_FAILED,
  GET_PELANGGAN_SUCCESS,
  GET_PELANGGAN_FAILED,
  GET_PELANGGAN_BLOKIR_SUCCESS,
  GET_PELANGGAN_BLOKIR_FAILED,
  GET_TOTAL_PELANGGAN_SUCCESS,
  GET_TOTAL_PELANGGAN_FAILED,
  GET_TOTAL_PELANGGAN_BLOKIR_SUCCESS,
  GET_TOTAL_PELANGGAN_BLOKIR_FAILED,
  GET_PELANGGAN,
  GET_PELANGGAN_BLOKIR,
  GET_PELANGGAN_VERIFIKASI_SUCCESS,
  GET_PELANGGAN_VERIFIKASI_FAILED,
  BANNED_PELANGGAN_FAILED,
  BANNED_PELANGGAN_SUCCESS,
  OPEN_BANNED_PELANGGAN_SUCCESS,
  OPEN_BANNED_PELANGGAN_FAILED,
  DELETE_PELANGGAN_FAILED,
  DELETE_PELANGGAN_SUCCESS,
  GET_SUPPLIER_REPLY_SUCCESS,
  GET_SUPPLIER_REPLY_FAILED,
  GET_TOTAL_SUPPLIER_REPLY_SUCCESS,
  GET_TOTAL_SUPPLIER_REPLY_FAILED,
  GET_CREATE_TRANSACTION_REPLY_SUCCESS,
  GET_CREATE_TRANSACTION_REPLY_FAILED,
  GET_TOTAL_CREATE_TRANSACTION_REPLY_SUCCESS,
  GET_TOTAL_CREATE_TRANSACTION_REPLY_FAILED,
  GET_CREATE_TRANSACTION_REPLY,
  GET_TOTAL_CREATE_TRANSACTION_REPLY,
  GET_SUPPLIER_REPLY,
  GET_TOTAL_SUPPLIER_REPLY,
  SET_FILTER_SUPPLIER_REPLY,
  RESET_FILTER_SUPPLIER_REPLY,
  GET_LIST_BLACKLIST_SUCCESS,
  GET_LIST_BLACKLIST_FAILED,
  GET_LIST_BLACKLIST,
  ADD_BLACKLIST_SUCCESS,
  ADD_BLACKLIST_FAILED,
  OPEN_BLACKLIST_SUCCESS,
  OPEN_BLACKLIST_FAILED,
  UPDATE_BLACKLIST_SUCCESS,
  UPDATE_BLACKLIST_FAILED,
  VERIFIKASI_PELANGGAN_SUCCESS,
  VERIFIKASI_PELANGGAN_FAILED,
  UNVERIFIKASI_PELANGGAN_SUCCESS,
  UNVERIFIKASI_PELANGGAN_FAILED,
} from "./constants";

const baseUrl = process.env.REACT_APP_API;

export const getPelanggan =
  (page, formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        if (pair[1] !== "undefined") {
          param[pair[0]] = pair[1];
        }
      }
      param.page = page;
    } else {
      param = {
        page: page,
      };
    }

    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/account/list`,
      params: param,
      resType: "list",
      type: GET_PELANGGAN,
      typeSuccess: GET_PELANGGAN_SUCCESS,
      typeFailed: GET_PELANGGAN_FAILED,
    });

    if (param.page === 1) {
      delete param.page;
      baseAction({
        dispatch: dispatch,
        method: "get",
        url: `${baseUrl}/account/list/count`,
        params: param,
        resType: "total",
        typeSuccess: GET_TOTAL_PELANGGAN_SUCCESS,
        typeFailed: GET_TOTAL_PELANGGAN_FAILED,
      });
    }
  };

export const verifikasiPelanggan = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/account/verifikasi`,
    params: {
      id: id,
    },
    resRequest: id,
    resType: "res-request",
    typeSuccess: VERIFIKASI_PELANGGAN_SUCCESS,
    typeFailed: VERIFIKASI_PELANGGAN_FAILED,
    notify: true,
  });
};

export const unverifikasiPelanggan = (id, desc) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/account/unverifikasi`,
    params: {
      id: id,
      keterangan: desc,
    },
    resRequest: id,
    resType: "res-request",
    typeSuccess: UNVERIFIKASI_PELANGGAN_SUCCESS,
    typeFailed: UNVERIFIKASI_PELANGGAN_FAILED,
    notify: true,
  });
};

export const bannedPelanggan = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/account/banned_account`,
    data: values,
    resRequest: values,
    resType: "res-request",
    typeSuccess: BANNED_PELANGGAN_SUCCESS,
    typeFailed: BANNED_PELANGGAN_FAILED,
    notify: true,
  });
};

export const getPelangganBlokir =
  (page, formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        if (pair[1] !== "undefined") {
          param[pair[0]] = pair[1];
        }
      }
      param.page = page;
    } else {
      param = {
        page: page,
      };
    }

    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/account/list`,
      params: param,
      resType: "list",
      type: GET_PELANGGAN_BLOKIR,
      typeSuccess: GET_PELANGGAN_BLOKIR_SUCCESS,
      typeFailed: GET_PELANGGAN_BLOKIR_FAILED,
    });

    if (param.page === 1) {
      delete param.page;
      baseAction({
        dispatch: dispatch,
        method: "get",
        url: `${baseUrl}/account/list/count`,
        params: param,
        resType: "total",
        typeSuccess: GET_TOTAL_PELANGGAN_BLOKIR_SUCCESS,
        typeFailed: GET_TOTAL_PELANGGAN_BLOKIR_FAILED,
      });
    }
  };

export const getPelangganVerifikasi = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/account/list_waiting`,
    resType: "list",
    typeSuccess: GET_PELANGGAN_VERIFIKASI_SUCCESS,
    typeFailed: GET_PELANGGAN_VERIFIKASI_FAILED,
  });
};

export const openBannedPelanggan = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/account/openbanned`,
    data: {
      id: id,
    },
    resRequest: id,
    resType: "res-request",
    typeSuccess: OPEN_BANNED_PELANGGAN_SUCCESS,
    typeFailed: OPEN_BANNED_PELANGGAN_FAILED,
    notify: true,
  });
};

export const deletePelanggan = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/account/delete_account`,
    data: {
      id: id,
    },
    resRequest: id,
    resType: "res-request",
    typeSuccess: DELETE_PELANGGAN_SUCCESS,
    typeFailed: DELETE_PELANGGAN_FAILED,
    notify: true,
  });
};

export const getTransaksiProduk =
  (page, formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        if (pair[1] !== "undefined") {
          param[pair[0]] = pair[1];
        }
      }
      param.page = page;
    } else {
      param = {
        page: page,
      };
    }

    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/account/history/product/all`,
      params: param,
      resType: "list",
      type: GET_TRANSAKSI_PRODUK,
      typeSuccess: GET_TRANSAKSI_PRODUK_SUCCESS,
      typeFailed: GET_TRANSAKSI_PRODUK_FAILED,
    });

    if (param.page === 1) {
      delete param.page;
      baseAction({
        dispatch: dispatch,
        method: "get",
        url: `${baseUrl}/account/history/product/count`,
        params: param,
        resType: "total",
        typeSuccess: GET_TOTAL_TRANSAKSI_PRODUK_SUCCESS,
        typeFailed: GET_TOTAL_TRANSAKSI_PRODUK_FAILED,
      });
    }
  };

export const loadTransaksiProduk = () => {
  return {
    type: GET_TRANSAKSI_PRODUK,
  };
};

export const getDetailProduk = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/account/history/product/detail/${id}`,
    resType: "one",
    type: GET_DETAIL_TRANSAKSI_PRODUK,
    typeSuccess: GET_DETAIL_TRANSAKSI_PRODUK_SUCCESS,
    typeFailed: GET_DETAIL_TRANSAKSI_PRODUK_FAILED,
  });
};

export const changeReferensiTransaksiProduk = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/account/referensi`,
    data: values,
    resRequest: false,
    resFailed1: true,
    resFailed2: true,
    resType: "res-request",
    typeSuccess: CHANGE_REFERENSI_TRANSAKSI_PRODUK_SUCCESS,
    typeFailed: CHANGE_REFERENSI_TRANSAKSI_PRODUK_FAILED,
    notify: true,
  });
};

export const changeNoteTransaksiProduk = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/account/notetrx`,
    data: values,
    resRequest: false,
    resFailed1: true,
    resFailed2: true,
    resType: "res-request",
    typeSuccess: CHANGE_REFERENSI_TRANSAKSI_PRODUK_SUCCESS,
    typeFailed: CHANGE_REFERENSI_TRANSAKSI_PRODUK_FAILED,
    notify: true,
  });
};

export const changeKeteranganTransaksiProduk =
  (id, values) => async (dispatch) => {
    baseAction({
      dispatch: dispatch,
      method: "post",
      url: `${baseUrl}/account/history/information/${id}`,
      data: values,
      resRequest: false,
      resFailed1: true,
      resFailed2: true,
      resType: "res-request",
      typeSuccess: CHANGE_KETERANGAN_TRANSAKSI_PRODUK_SUCCESS,
      typeFailed: CHANGE_KETERANGAN_TRANSAKSI_PRODUK_FAILED,
      notify: true,
    });
  };

export const sukseskanTransaksiProduk =
  (code, note, referensi) => async (dispatch) => {
    return basePromiseAction({
      dispatch: dispatch,
      method: "post",
      url: `${baseUrl}/account/sukseskantrx`,
      data: { id_transaction: code, note: note, referensi: referensi },
      resType: "description",
      typeSuccess: SUKSESKAN_TRANSAKSI_PRODUK_SUCCESS,
      typeFailed: SUKSESKAN_TRANSAKSI_PRODUK_FAILED,
      notify: true,
    });
  };

export const gagalkanTransaksiProduk = (code) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/account/canceltrx`,
    data: {
      id_transaction: code,
    },
    resType: "description",
    typeSuccess: GAGALKAN_TRANSAKSI_PRODUK_SUCCESS,
    typeFailed: GAGALKAN_TRANSAKSI_PRODUK_FAILED,
    notify: true,
  });
};

export const getTransaksiDeposit =
  (page, formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        if (pair[1] !== "undefined") {
          param[pair[0]] = pair[1];
        }
      }
      param.page = page;
    } else {
      param = {
        page: page,
      };
    }

    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/account/history/deposit/all`,
      params: param,
      resType: "list",
      type: GET_TRANSAKSI_DEPOSIT,
      typeSuccess: GET_TRANSAKSI_DEPOSIT_SUCCESS,
      typeFailed: GET_TRANSAKSI_DEPOSIT_FAILED,
    });

    if (param.page === 1) {
      delete param.page;
      baseAction({
        dispatch: dispatch,
        method: "get",
        url: `${baseUrl}/account/history/deposit/count`,
        params: param,
        resType: "total",
        typeSuccess: GET_TOTAL_TRANSAKSI_DEPOSIT_SUCCESS,
        typeFailed: GET_TOTAL_TRANSAKSI_DEPOSIT_FAILED,
      });
    }
  };

export const loadTransaksiDeposit = () => {
  return {
    type: GET_TRANSAKSI_DEPOSIT,
  };
};

export const getListMetodeDeposit = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/account/history/deposit/type`,
    resType: "one",
    typeSuccess: GET_METODE_DEPOSIT_SUCCESS,
    typeFailed: GET_METODE_DEPOSIT_FAILED,
  });
};

export const getDetailDeposit = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/account/history/deposit/detail/${id}`,
    resType: "one",
    type: GET_DETAIL_TRANSAKSI_DEPOSIT,
    typeSuccess: GET_DETAIL_TRANSAKSI_DEPOSIT_SUCCESS,
    typeFailed: GET_DETAIL_TRANSAKSI_DEPOSIT_FAILED,
  });
};

export const changeTotalTransaksiDeposit = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/account/updatedeposit`,
    data: values,
    resRequest: false,
    resFailed1: true,
    resFailed2: true,
    resType: "res-request",
    typeSuccess: CHANGE_TOTAL_TRANSAKSI_DEPOSIT_SUCCESS,
    typeFailed: CHANGE_TOTAL_TRANSAKSI_DEPOSIT_FAILED,
    notify: true,
  });
};

export const sukseskanTransaksiDeposit = (values) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/deposit/sukseskan`,
    data: values,
    resRequest: false,
    resFailed1: true,
    resFailed2: true,
    resType: "res-request",
    typeSuccess: SUKSESKAN_TRANSAKSI_DEPOSIT_SUCCESS,
    typeFailed: SUKSESKAN_TRANSAKSI_DEPOSIT_FAILED,
    notify: true,
  });
};

export const sukseskanTransaksiDepositOld = (code) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/deposit/sukseskan`,
    data: { id_transaction: code },
    resType: "description",
    typeSuccess: SUKSESKAN_TRANSAKSI_DEPOSIT_SUCCESS,
    typeFailed: SUKSESKAN_TRANSAKSI_DEPOSIT_FAILED,
    notify: true,
  });
};

export const gagalkanTransaksiDeposit = (code) => async (dispatch) => {
  return basePromiseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/deposit/gagalkan`,
    data: { id_transaction: code },
    resType: "description",
    typeSuccess: GAGALKAN_TRANSAKSI_DEPOSIT_SUCCESS,
    typeFailed: GAGALKAN_TRANSAKSI_DEPOSIT_FAILED,
    notify: true,
  });
};

export const getTransaksiPengecekan =
  (page, formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        if (pair[1] !== "undefined") {
          param[pair[0]] = pair[1];
        }
      }
      param.page = page;
    } else {
      param = {
        page: page,
      };
    }

    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/account/history/product_check/all`,
      params: param,
      resType: "list",
      type: GET_TRANSAKSI_PENGECEKAN,
      typeSuccess: GET_TRANSAKSI_PENGECEKAN_SUCCESS,
      typeFailed: GET_TRANSAKSI_PENGECEKAN_FAILED,
    });

    if (param.page === 1) {
      delete param.page;
      baseAction({
        dispatch: dispatch,
        method: "get",
        url: `${baseUrl}/account/history/product_check/count`,
        params: param,
        resType: "total",
        typeSuccess: GET_TOTAL_TRANSAKSI_PENGECEKAN_SUCCESS,
        typeFailed: GET_TOTAL_TRANSAKSI_PENGECEKAN_FAILED,
      });
    }
  };

export const loadTransaksiPengecekan = () => {
  return {
    type: GET_TRANSAKSI_PENGECEKAN,
  };
};

export const getDetailPengecekan = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/account/history/product_check/detail/${id}`,
    resType: "one",
    type: GET_DETAIL_TRANSAKSI_PENGECEKAN,
    typeSuccess: GET_DETAIL_TRANSAKSI_PENGECEKAN_SUCCESS,
    typeFailed: GET_DETAIL_TRANSAKSI_PENGECEKAN_FAILED,
  });
};

export const getSupplierReply =
  (page, formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        param[pair[0]] = pair[1];
      }
      param.page = page;
    } else {
      param = {
        page: page,
      };
    }

    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/log/supplierreply/all`,
      params: param,
      resType: "list",
      type: GET_SUPPLIER_REPLY,
      typeSuccess: GET_SUPPLIER_REPLY_SUCCESS,
      typeFailed: GET_SUPPLIER_REPLY_FAILED,
    });

    if (param.page === 1) {
      baseAction({
        dispatch: dispatch,
        method: "get",
        url: `${baseUrl}/log/supplierreply/count`,
        params: param,
        resType: "total",
        type: GET_TOTAL_SUPPLIER_REPLY,
        typeSuccess: GET_TOTAL_SUPPLIER_REPLY_SUCCESS,
        typeFailed: GET_TOTAL_SUPPLIER_REPLY_FAILED,
      });
    }
  };

export const getCreateTransactionReply =
  (page, formData = null) =>
  async (dispatch) => {
    let param = {};
    if (formData != null) {
      for (const pair of formData.entries()) {
        param[pair[0]] = pair[1];
      }
      param.page = page;
    } else {
      param = {
        page: page,
      };
    }

    baseAction({
      dispatch: dispatch,
      method: "get",
      url: `${baseUrl}/log/createtransactionreply/all`,
      params: param,
      resType: "list",
      type: GET_CREATE_TRANSACTION_REPLY,
      typeSuccess: GET_CREATE_TRANSACTION_REPLY_SUCCESS,
      typeFailed: GET_CREATE_TRANSACTION_REPLY_FAILED,
    });

    if (param.page === 1) {
      baseAction({
        dispatch: dispatch,
        method: "get",
        url: `${baseUrl}/log/createtransactionreply/count`,
        params: param,
        resType: "total",
        type: GET_TOTAL_CREATE_TRANSACTION_REPLY,
        typeSuccess: GET_TOTAL_CREATE_TRANSACTION_REPLY_SUCCESS,
        typeFailed: GET_TOTAL_CREATE_TRANSACTION_REPLY_FAILED,
      });
    }
  };

export const getListFeedback = (page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/feedback`,
    params: {
      page: page,
    },
    resType: "list-total",
    typeSuccess: GET_LIST_FEEDBACK_SUCCESS,
    typeFailed: GET_LIST_FEEDBACK_FAILED,
  });
};

export const loadFeedback = () => {
  return {
    type: GET_LIST_FEEDBACK,
  };
};

export const getListKategoriFeedback = () => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/feedback/category`,
    resType: "one",
    typeSuccess: GET_LIST_KATEGORI_FEEDBACK_SUCCESS,
    typeFailed: GET_LIST_KATEGORI_FEEDBACK_FAILED,
  });
};

export const getFilterListFeedback = (values, page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/feedback/filter`,
    params: {
      page: page,
      category: values.category,
      status: values.status,
      date_limit: values.date_limit,
      startDate: values.startDate,
      endDate: values.endDate,
    },
    resType: "list-total",
    typeSuccess: GET_LIST_FEEDBACK_SUCCESS,
    typeFailed: GET_LIST_FEEDBACK_FAILED,
    type: GET_LIST_FEEDBACK,
  });
};

export const searchListFeedback = (values, page) => async (dispatch) => {
  let param = {
    page: page,
  };
  if (values.option === "iduser") param.id_user = values.text;
  else if (values.option === "nama") param.name = values.text;
  else if (values.option === "notelepon") param.phone = values.text;
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/feedback/search`,
    params: param,
    resType: "list-total",
    typeSuccess: GET_LIST_FEEDBACK_SUCCESS,
    typeFailed: GET_LIST_FEEDBACK_FAILED,
    type: GET_LIST_FEEDBACK,
  });
};

export const getDetailFeedback = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/feedback/${id}`,
    resType: "one",
    type: GET_DETAIL_FEEDBACK,
    typeSuccess: GET_DETAIL_FEEDBACK_SUCCESS,
    typeFailed: GET_DETAIL_FEEDBACK_FAILED,
  });
};

export const backlogFeedback = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "put",
    url: `${baseUrl}/feedback/backlog/${id}`,
    data: {},
    resRequest: id,
    resType: "res-request",
    typeSuccess: BACKLOG_FEEDBACK_SUCCESS,
    typeFailed: BACKLOG_FEEDBACK_FAILED,
  });
};

export const cancelBacklogFeedback = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "put",
    url: `${baseUrl}/feedback/backlog/cancel/${id}`,
    data: {},
    resRequest: id,
    resType: "res-request",
    typeSuccess: CANCEL_BACKLOG_FEEDBACK_SUCCESS,
    typeFailed: CANCEL_BACKLOG_FEEDBACK_FAILED,
  });
};

export const deleteFeedback = (id) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "delete",
    url: `${baseUrl}/feedback/${id}`,
    resRequest: id,
    resType: "res-request",
    typeSuccess: DELETE_FEEDBACK_SUCCESS,
    typeFailed: DELETE_FEEDBACK_FAILED,
  });
};

export const checkStatusNobu = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/deposit/nobu_inquiry_payment`,
    data: values,
    typeSuccess: GET_STATUS_NOBU_SUCCESS,
    typeFailed: GET_STATUS_NOBU_FAILED,
    notify: true,
    // mockHttp: axiosSuccess,
    // mockData: mockCheckStatusNobuSuccess,
  });
};

export const getMutasiSalahTransfer = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/finance/cekmutasi`,
    data: values,
    resType: "list",
    typeSuccess: GET_MUTASI_SALAH_TRANSFER_SUCCESS,
    typeFailed: GET_MUTASI_SALAH_TRANSFER_FAILED,
    type: GET_MUTASI_SALAH_TRANSFER,
    // mockHttp: axiosSuccess,
    // mockData: mockChekMutasiSuccess,
  });
};

export const klaimMutasi = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/finance/claim`,
    data: values,
    // resType: "description",
    type: SUKSESKAN_SALAH_TRANSFER,
    typeSuccess: SUKSESKAN_SALAH_TRANSFER_SUCCESS,
    typeFailed: SUKSESKAN_SALAH_TRANSFER_FAILED,
    notify: true,
    // mockHttp: axiosSuccess,
    // mockData: mockSalahTransfer,
  });
};

export const setFilterSupplierReply = (formData) => async (dispatch) => {
  dispatch({
    type: SET_FILTER_SUPPLIER_REPLY,
    payload: formData,
  });
};

export const resetFilterSupplierReply = () => async (dispatch) => {
  dispatch({
    type: RESET_FILTER_SUPPLIER_REPLY,
  });
};

export const getListBlacklist = (page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/account/blacklist`,
    params: {
      page: page,
    },
    resType: "list-total",
    typeSuccess: GET_LIST_BLACKLIST_SUCCESS,
    typeFailed: GET_LIST_BLACKLIST_FAILED,
  });
};

export const loadBlacklist = () => {
  return {
    type: GET_LIST_FEEDBACK,
  };
};

export const getFilterListBlacklist = (values, page) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/account/blacklist`,
    params: {
      page: page,
      status: values.status,
    },
    resType: "list-total",
    typeSuccess: GET_LIST_BLACKLIST_SUCCESS,
    typeFailed: GET_LIST_BLACKLIST_FAILED,
    type: GET_LIST_BLACKLIST,
  });
};

export const searchListBlacklist = (values, page) => async (dispatch) => {
  let param = {
    page: page,
  };
  console.log("search values: " + JSON.stringify(values));
  if (values.option === "nomor") param.nomor = values.text;
  else if (values.option === "keterangan") param.keterangan = values.text;
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/account/blacklist`,
    params: param,
    resType: "list-total",
    typeSuccess: GET_LIST_BLACKLIST_SUCCESS,
    typeFailed: GET_LIST_BLACKLIST_FAILED,
    type: GET_LIST_BLACKLIST,
  });
};

export const addBlacklist = (values) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "post",
    url: `${baseUrl}/account/blacklist`,
    data: values,
    resType: "description",
    typeSuccess: ADD_BLACKLIST_SUCCESS,
    typeFailed: ADD_BLACKLIST_FAILED,
    notify: true,
  });
};

export const updateBlacklist = (number) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/account/blacklist/blokir`,
    params: { nomor: number },
    resRequest: { number: number, status: 0 },
    resType: "res-request",
    typeSuccess: UPDATE_BLACKLIST_SUCCESS,
    typeFailed: UPDATE_BLACKLIST_FAILED,
    notify: true,
  });
};

export const openBlacklist = (number) => async (dispatch) => {
  baseAction({
    dispatch: dispatch,
    method: "get",
    url: `${baseUrl}/account/blacklist/openban`,
    params: { nomor: number },
    resRequest: { number: number, status: 1 },
    resType: "res-request",
    typeSuccess: OPEN_BLACKLIST_SUCCESS,
    typeFailed: OPEN_BLACKLIST_FAILED,
    notify: true,
  });
};

// 1373
