import { Button, Dropdown, Menu, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeader } from "../../../containers/Main/actions";
import { deleteInformasiMenarik } from "../../../containers/Publish/action";
import { ArrowLeftOutlined } from "@ant-design/icons";
import MoreFillIcon from "remixicon-react/MoreFillIcon";
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";

const HeaderSplashScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { menu, form, loading, code } = props;
  const { confirm } = Modal;

  const editData = useSelector(
    (state) => state.publish.editDataInformasiMenarik
  );

  const handleBackScreen = async () => {
    confirm({
      title: "Yakin ingin Batalkan ?",
      icon: null,
      content: "",
      centered: true,
      okText: "Ya, Batal",
      cancelText: "Tidak",
      async onOk() {
        await dispatch(setHeader("default"));
        localStorage.setItem("otherTemplate", "false");
        form.resetFields();
        navigate("/publish/informasi/splashscreen");
      },
      onCancel() {
        console.log("Cancel hapus");
      },
    });
  };

  const deleteSplashScreen = (id) => {
    confirm({
      title: "Yakin ingin Hapus ?",
      icon: null,
      content: "",
      centered: true,
      okText: "Ya, Hapus",
      cancelText: "Tidak",
      async onOk() {
        await dispatch(deleteInformasiMenarik(id));
        await dispatch(setHeader("default"));
        window.setTimeout(() => {
          localStorage.setItem("otherTemplate", "false");
          form.resetFields();
          navigate("/publish/informasi/splashscreen");
        }, 1000);
      },
      onCancel() {},
    });
  };

  const menuAction = (id, status) => (
    <Menu>
      <Menu.Item key="hapus" danger onClick={() => deleteSplashScreen(id)}>
        <DeleteBinLineIcon />
        <span>Hapus Splash Screen</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="leftHeader">
        <div className="actionBack">
          <ArrowLeftOutlined onClick={() => handleBackScreen()} />
        </div>
        <h1>
          {menu === "tambahsplashscreen"
            ? "Tambah Splash Screen"
            : "Detail Splash Screen"}
        </h1>
      </div>
      <div className="rightHeader">
        <Button
          className="btnAct btnActCancel"
          onClick={() => handleBackScreen()}
        >
          Batal
        </Button>
        <Button
          className="btnAct btnActSubmit"
          onClick={() => form.submit()}
          loading={loading}
        >
          {menu === "tambahsplashscreen" ? "Tambah" : "Simpan"}
        </Button>
        {menu === "detailsplashscreen" ? (
          <Dropdown
            overlay={menuAction(code, editData.status)}
            placement="bottomRight"
            trigger={["click"]}
          >
            <Button className="btnAct btnMenuAction">
              <MoreFillIcon className="dropdownIcon" />
            </Button>
          </Dropdown>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default HeaderSplashScreen;
