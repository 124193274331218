import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Dropdown, Form, Input, Menu, Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckLineIcon from "remixicon-react/CheckLineIcon";
import InformationLineIcon from "remixicon-react/InformationLineIcon";
import MoreFillIcon from "remixicon-react/MoreFillIcon";
import { setHeader } from "../../../containers/Main/actions";
import {
  gagalkanTransaksiDeposit,
  gagalkanTransaksiProduk,
  getDetailDeposit,
  getDetailProduk,
  sukseskanTransaksiDeposit,
  sukseskanTransaksiProduk,
  checkStatusNobu,
} from "../../../containers/Pelanggan/actions";
import FloatInput from "../../FloatForm/FloatInput";

const HeaderTransaksi = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const action = useSelector((state) => state.pelanggan.actionTransaksi);
  const infoDataProduk = useSelector((state) => state.pelanggan.infoDataProduk);
  const infoReplyNobu = useSelector((state) => state.pelanggan.infoReplyNobu);

  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loadingNobu, setLoadingNobu] = useState(false);
  const [visibleNobu, setVisibleNobu] = useState(false);
  const [contentNote, setContentNote] = useState(false);
  const [form] = Form.useForm();
  const [formHeader] = Form.useForm();

  const { menu, trx_id, totalDeposit } = props;
  const { confirm } = Modal;

  useEffect(() => {
    if (
      infoDataProduk !== null &&
      (action.sukseskan === true || action.gagalkan === true)
    ) {
      setContentNote(
        <Form form={formHeader}>
          <FloatInput
            label="Catatan"
            placeholder="Catatan"
            name="note"
            reset={reset}
            setReset={setReset}
            always={true}
          />
          <FloatInput
            label="Referensi"
            placeholder="Referensi"
            name="referensi"
            reset={reset}
            setReset={setReset}
            always={true}
          />
        </Form>
      );

      formHeader.setFieldsValue({
        note: infoDataProduk.note,
        referensi: infoDataProduk.referensi,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoDataProduk]);

  const handleBackScreen = async () => {
    navigate(-1);
  };

  const confirmSukseskanTransaksi = (id, tipe) => {
    if (tipe === "detailproduk") {
      confirm({
        title: "Yakin ingin Sukseskan Transaksi ?",
        icon: null,
        centered: true,
        okText: "Ya, Sukseskan",
        cancelText: "Tidak",
        content: contentNote,
        async onOk() {
          setLoading(true);
          let note = formHeader.getFieldValue("note") || "";
          let referensi = formHeader.getFieldValue("referensi") || "";
          dispatch(sukseskanTransaksiProduk(id, note, referensi)).then(() => {
            dispatch(getDetailProduk(id));
          });
          setLoading(false);
        },
        onCancel() {
          console.log("Cancel sukseskan");
        },
      });
    } else if (tipe === "detaildeposit") {
      setVisibleNobu(false);
      setVisible(true);
    }
  };

  const confirmGagalkanTransaksi = (id, tipe) => {
    confirm({
      title: "Yakin ingin Gagalkan Transaksi ?",
      icon: null,
      centered: true,
      okText: "Ya, Gagalkan",
      cancelText: "Tidak",
      content: "",
      async onOk() {
        setLoading(true);
        if (tipe === "detailproduk") {
          dispatch(gagalkanTransaksiProduk(id)).then(() => {
            dispatch(getDetailProduk(id));
          });
        } else if (tipe === "detaildeposit") {
          dispatch(gagalkanTransaksiDeposit(id)).then(() => {
            dispatch(getDetailDeposit(id));
          });
        }
        setLoading(false);
      },
      onCancel() {
        console.log("Cancel sukseskan");
      },
    });
  };

  const onFinish = async (values) => {
    setLoading(true);
    await dispatch(sukseskanTransaksiDeposit(values));
    setLoading(false);
    form.resetFields();
    formHeader.resetFields();
    setReset(true);
    await dispatch(setHeader("default"));
    localStorage.setItem("otherTemplate", "false");
    navigate("/pelanggan/transaksi/deposit");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed: ", errorInfo);
  };

  const handleChangeNumber = (event) => {
    let value = null;
    if (event.target.value === "") value = 0;
    else {
      value = parseInt(
        event.target.value.replace(",00", "").replace(/\D/g, "")
      );
    }
    form.setFieldsValue({ total: value });
  };

  const handleCancelKonfirmasi = () => {
    setVisibleNobu(false);
    setVisible(false);
  };

  const handleCheckNobu = async (trx_id) => {
    setLoadingNobu(true);
    let values = { id_transaction: trx_id };
    await dispatch(checkStatusNobu(values));
    setLoadingNobu(false);
  };

  useEffect(() => {
    let total = totalDeposit;
    let newTotal = "0";
    if (total !== undefined) {
      newTotal = parseInt(total.replace(",00", "").replace(/\D/g, ""));
    }

    form.setFieldsValue({
      id_transaction: trx_id,
      total: newTotal,
    });
  }, [form, trx_id, totalDeposit]);

  useEffect(() => {
    if (infoReplyNobu != null) {
      setVisibleNobu(true);
    }
  }, [infoReplyNobu]);

  const menuAction = (trx_id, action) => {
    let menus = [];
    if (action.sukseskan_nobu === true) {
      menus.push(
        <Menu.Item
          key="check_status_nobu"
          onClick={() => handleCheckNobu(trx_id)}
        >
          <InformationLineIcon />
          <span>Check Status Nobu</span>
        </Menu.Item>
      );
    }

    return <Menu>{menus}</Menu>;
  };

  return (
    <>
      <div className="leftHeader">
        <div className="actionBack">
          <ArrowLeftOutlined onClick={() => handleBackScreen()} />
        </div>
        <h1>
          {menu === "detailproduk"
            ? "Detail Transaksi Produk"
            : menu === "detaildeposit"
            ? "Detail Transaksi Deposit"
            : ""}
        </h1>
      </div>
      <div className="rightHeader">
        {action.gagalkan === true ? (
          <Button
            className="btnAct btnActAlert"
            loading={loading}
            onClick={() => confirmGagalkanTransaksi(trx_id, menu)}
          >
            Gagalkan
          </Button>
        ) : (
          <Button disabled className="btnAct btnActAlert">
            Gagalkan
          </Button>
        )}
        {action.sukseskan === true ? (
          <>
            <Button
              className="btnAct btnActSubmit"
              loading={loading}
              onClick={() => confirmSukseskanTransaksi(trx_id, menu)}
            >
              Sukseskan
            </Button>
            {action.sukseskan_nobu === true && (
              <Dropdown
                overlay={menuAction(trx_id, action)}
                placement="bottomRight"
                trigger={["click"]}
              >
                <Button className="btnAct btnMenuAction" loading={loadingNobu}>
                  <MoreFillIcon className="dropdownIcon" />
                </Button>
              </Dropdown>
            )}
          </>
        ) : (
          <Button disabled className="btnAct btnActSubmit">
            Sukseskan
          </Button>
        )}
      </div>

      <Modal
        className="modalInputKonfirmasi"
        title={
          <div className="headerKonfirmasi">
            <h3>Sukseskan Deposit</h3>
          </div>
        }
        visible={visible}
        closable={false}
        footer={null}
      >
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <div className="contentKonfirmasi">
            <Form.Item
              name="id_transaction"
              style={{ marginBottom: "0px", height: "0px" }}
            >
              <Input type="hidden" disabled />
            </Form.Item>
            <FloatInput
              label="Total"
              placeholder="Total"
              name="total"
              prefix="Rp"
              type="change"
              onChange={handleChangeNumber}
              reset={reset}
              setReset={setReset}
              data={props.totalDeposit}
              always={true}
            />
          </div>
          <div className="submitKonfirmasi">
            <Button
              key="back"
              className="btnBatal"
              onClick={handleCancelKonfirmasi}
            >
              Batal
            </Button>
            <Button
              key="submit"
              type="primary"
              loading={loading}
              htmlType="submit"
              className="btnSubmit"
            >
              Sukseskan
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        className="modalInputKonfirmasi"
        title={
          <div className="headerKonfirmasi">
            <h3>Status Deposit Nobu</h3>
          </div>
        }
        visible={visibleNobu}
        closable={false}
        footer={null}
      >
        <div className="contentKonfirmasi">
          <ul className="listDetail">
            <li>
              <h4>Amount</h4>
              <h3>{infoReplyNobu?.amount ?? ""}</h3>
            </li>
            <li>
              <h4>Payment Status</h4>
              <h3 className="successMenu">
                <CheckLineIcon />
                <span>{infoReplyNobu?.paymentStatus ?? ""}</span>
              </h3>
            </li>
            <li>
              <h4>Payment Reference No</h4>
              <h3>{infoReplyNobu?.paymentReferenceNo ?? ""}</h3>
            </li>
            <li>
              <h4>Payment Date</h4>
              <h3>{infoReplyNobu?.paymentDate ?? ""}</h3>
            </li>
            <li>
              <h4>Retrieval Reference No</h4>
              <h3>{infoReplyNobu?.retrievalReferenceNo ?? ""}</h3>
            </li>
          </ul>
        </div>
        <div className="submitKonfirmasi">
          <Button
            key="back"
            className="btnBatal"
            onClick={handleCancelKonfirmasi}
          >
            Batal
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={loading}
            htmlType="submit"
            className="btnSubmit"
            onClick={() => confirmSukseskanTransaksi(trx_id, menu)}
          >
            Sukseskan
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default HeaderTransaksi;
