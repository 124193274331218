import { Layout, Form } from "antd";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Suspense, lazy, useState } from "react";
import { setHeader } from "../../containers/Main/actions";
import {
  addProdukMargin,
  updateProdukMargin,
} from "../../containers/Produk/actions";
import { updateSetMetode } from "../../containers/Deposit/actions";
import { publishInformasiProduk } from "../../containers/Publish/action";
import moment from "moment";
import HeaderForm from "./HeaderForm";
import FallbackLoader from "../FallbackLoader";
import FormSetOTP from "../../containers/Integrasi/FormSetOTP";
import DetailSetMetode from "../../containers/Deposit/DetailSetMetode";
const TambahProduk = lazy(() => import("../../containers/Produk/TambahProduk"));
const FormTambahProductDetail = lazy(() =>
  import("../../containers/Produk/FormTambahProductDetail")
);
const DetailProduk = lazy(() => import("../../containers/Produk/DetailProduk"));
const FormInformasiProduk = lazy(() =>
  import("../../containers/Publish/FormInformasiProduk")
);
const FormPromoFlashsale = lazy(() =>
  import("../../containers/Publish/FormPromoFlashsale")
);
const DetailProdukPelanggan = lazy(() =>
  import("../../containers/Pelanggan/DetailProduk")
);
const DetailDeposit = lazy(() =>
  import("../../containers/Pelanggan/DetailDeposit")
);
const SalahTransfer = lazy(() =>
  import("../../containers/Pelanggan/SalahTransfer")
);
const DetailPengecekan = lazy(() =>
  import("../../containers/Pelanggan/DetailPengecekan")
);
const DetailFeedback = lazy(() =>
  import("../../containers/Pelanggan/DetailFeedback")
);
const DetailPelanggan = lazy(() =>
  import("../../containers/Pelanggan/DetailPelanggan")
);
const FormTambahPengguna = lazy(() =>
  import("../../containers/Pengguna/FormTambahPengguna")
);
const TambahJabatan = lazy(() =>
  import("../../containers/Pengguna/TambahJabatan")
);
const FormTambahParent = lazy(() =>
  import("../../containers/Pengguna/FormTambahParent")
);
const FormTambahMenu = lazy(() =>
  import("../../containers/Pengguna/FormTambahMenu")
);
const FormTambahFitur = lazy(() =>
  import("../../containers/Pengguna/FormTambahFitur")
);
const FormPromoSpesial = lazy(() =>
  import("../../containers/Publish/FormPromoSpesial")
);
const FormPromoKupon = lazy(() =>
  import("../../containers/Publish/FormPromoKupon")
);
const FormInformasiPemberitahuan = lazy(() =>
  import("../../containers/Publish/FormInformasiPemberitahuan")
);
const FormInformasiMenarik = lazy(() =>
  import("../../containers/Publish/FormInformasiMenarik")
);
const FormBlacklist = lazy(() =>
  import("../../containers/Pelanggan/FormBlacklist")
);
const FormSplashScreen = lazy(() =>
  import("../../containers/Publish/FormSplashScreen")
);

const { Content } = Layout;

const FormPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editProduk = useSelector((state) => state.produk.editProduk);
  const editPromoFlashsale = useSelector(
    (state) => state.publish.editPromoFlashsale
  );
  const editPromoSpesial = useSelector(
    (state) => state.publish.editPromoSpesial
  );
  const editPromoKupon = useSelector((state) => state.publish.editPromoKupon);
  const editInformasiPemberitahuan = useSelector(
    (state) => state.publish.editInformasiPemberitahuan
  );
  const editInformasiMenarik = useSelector(
    (state) => state.publish.editInformasiMenarik
  );
  const editInformasiProduk = useSelector(
    (state) => state.publish.editInformasiProduk
  );
  const editSplashScreen = useSelector(
    (state) => state.publish.editSplashScreen
  );
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [reset, setReset] = useState(true);
  const [totalDeposit, setTotalDeposit] = useState("0");
  const [uploadImage, setUploadImage] = useState([]);

  const [formProduk] = Form.useForm();
  const [formProdukMargin] = Form.useForm();
  const [formDepositMetode] = Form.useForm();
  const [formPromoFlashsale] = Form.useForm();
  const [formPromoSpesial] = Form.useForm();
  const [formPromoKupon] = Form.useForm();
  const [formInformasiPemberitahuan] = Form.useForm();
  const [formInformasiMenarik] = Form.useForm();
  const [formInformasiProduk] = Form.useForm();
  const [formSplashScreen] = Form.useForm();
  const [formSalahTransfer] = Form.useForm();
  const [formIntegrasiOTP] = Form.useForm();
  const [formTambahPengguna] = Form.useForm();
  const [formTambahBlacklist] = Form.useForm();
  const [formJabatan] = Form.useForm();
  const [formParent] = Form.useForm();
  const [formMenu] = Form.useForm();
  const [formFitur] = Form.useForm();

  const resetProduk = () => {
    setReset(true);
    formProduk.resetFields();
  };

  const handleFinishProdukMargin = async (values) => {
    if (
      values.start_date === undefined ||
      values.start_time === undefined ||
      values.end_date === undefined ||
      values.end_time === undefined
    ) {
      console.log("gagal set margin");
    } else {
      setLoadingSubmit(true);
      let formData = new FormData();
      formData.append("code_product", values.code_product);
      formData.append("id_margin", values.id_margin);
      formData.append("margin", values.margin);
      formData.append(
        "start_date",
        moment(values.start_date).format("YYYY-MM-DD")
      );
      formData.append("start_time", moment(values.start_time).format("HH:mm"));
      formData.append("end_date", moment(values.end_date).format("YYYY-MM-DD"));
      formData.append("end_time", moment(values.end_time).format("HH:mm"));

      if (
        values.id_margin === null ||
        values.id_margin === "" ||
        values.id_margin === undefined
      ) {
        formData.delete("id_margin");
        await dispatch(addProdukMargin(formData));
      } else {
        await dispatch(updateProdukMargin(formData));
      }
    }
    setLoadingSubmit(false);
    setReset(true);
    localStorage.setItem("otherTemplate", "false");
    await dispatch(setHeader("default"));
    formProdukMargin.resetFields();
    if (editProduk === true) navigate("/produk/list");
    else navigate("/produk/setproduk");
  };

  const handleFinishDepositMetode = async (values) => {
    console.log("finish deposit");
    setLoadingSubmit(true);
    // miss upload image [sesuaikan dgn provider]
    await dispatch(updateSetMetode(values));
    setLoadingSubmit(false);
    setReset(true);
    localStorage.setItem("otherTemplate", "false");
    await dispatch(setHeader("default"));
    formDepositMetode.resetFields();
    navigate("/deposit/setmetode");
  };

  const handleFinishInformasiProduk = async (values) => {
    setLoadingSubmit(true);
    let data = { ...values, uploadImage };
    let formData = new FormData();
    if (
      data.id_publish === undefined ||
      data.id_publish === null ||
      data.id_publish === ""
    ) {
    } else formData.append("id", data.id_publish);
    if (
      data.product === undefined ||
      data.product === null ||
      data.product === ""
    ) {
    } else formData.append("produk", data.product);
    if (
      data.provider === undefined ||
      data.provider === null ||
      data.provider === ""
    ) {
    } else formData.append("provider", data.provider);
    if (data.type === undefined || data.type === null || data.type === "") {
    } else formData.append("type", data.type);
    if (
      data.subtype === undefined ||
      data.subtype === null ||
      data.subtype === ""
    ) {
    } else formData.append("sub_type", data.subtype);
    if (
      data.category === undefined ||
      data.category === null ||
      data.category === ""
    ) {
    } else formData.append("category", data.category);
    if (
      data.bullet === undefined ||
      data.bullet === null ||
      data.bullet === ""
    ) {
    } else formData.append("bullet", data.bullet);
    if (data.title === undefined || data.title === null || data.title === "") {
    } else formData.append("judul", data.title);
    if (
      data.footer === undefined ||
      data.footer === null ||
      data.footer === ""
    ) {
    } else formData.append("footer", data.footer);
    if (data.id_publish === undefined) formData.append("republish", 0);
    else formData.append("republish", 1);
    if (uploadImage.length > 0)
      formData.append("image", data.uploadImage[0].originFileObj);

    await dispatch(publishInformasiProduk(formData));
    window.setTimeout(async () => {
      setLoadingSubmit(false);
      setReset(true);
      localStorage.setItem("otherTemplate", "false");
      await dispatch(setHeader("default"));
      formInformasiProduk.resetFields();
      navigate("/publish/informasi/produk");
    }, 5000);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed: ", errorInfo);
  };

  return (
    <>
      <HeaderForm
        loading={loadingSubmit}
        formProduk={formProduk}
        formProdukMargin={formProdukMargin}
        formDepositMetode={formDepositMetode}
        formPromoFlashsale={formPromoFlashsale}
        formPromoSpesial={formPromoSpesial}
        formPromoKupon={formPromoKupon}
        formInformasiPemberitahuan={formInformasiPemberitahuan}
        formInformasiMenarik={formInformasiMenarik}
        formSplashScreen={formSplashScreen}
        formInformasiProduk={formInformasiProduk}
        formSalahTransfer={formSalahTransfer}
        formIntegrasiOTP={formIntegrasiOTP}
        formTambahPengguna={formTambahPengguna}
        formJabatan={formJabatan}
        formParent={formParent}
        formMenu={formMenu}
        formTambahBlacklist={formTambahBlacklist}
        formFitur={formFitur}
        fnReset={resetProduk}
        totalDeposit={totalDeposit}
        setTotalDeposit={setTotalDeposit}
      />
      <Layout>
        <Content>
          <div className="wrapAltContainer">
            <Suspense fallback={<FallbackLoader />}>
              <Routes>
                <Route
                  path="/produk/detailproduk/:code"
                  element={
                    <DetailProduk
                      edit={editProduk}
                      form={formProduk}
                      formMargin={formProdukMargin}
                      onFinishMargin={handleFinishProdukMargin}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/produk/tambahproduk/:code"
                  element={
                    <TambahProduk
                      form={formProduk}
                      formMargin={formProdukMargin}
                      onFinishMargin={handleFinishProdukMargin}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/produk/tambah_produk_detail"
                  element={
                    <FormTambahProductDetail
                      form={formProduk}
                      setLoading={setLoadingSubmit}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/deposit/detailsetmetode/:code"
                  element={
                    <DetailSetMetode
                      form={formDepositMetode}
                      onFinish={handleFinishDepositMetode}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/publish/promo/tambahflashsale"
                  element={
                    <FormPromoFlashsale
                      edit={editPromoFlashsale}
                      form={formPromoFlashsale}
                      setLoading={setLoadingSubmit}
                    />
                  }
                />
                <Route
                  path="/publish/promo/detailflashsale/:code"
                  element={
                    <FormPromoFlashsale
                      edit={editPromoFlashsale}
                      form={formPromoFlashsale}
                      setLoading={setLoadingSubmit}
                    />
                  }
                />
                <Route
                  path="/publish/promo/tambahspesial"
                  element={
                    <FormPromoSpesial
                      edit={editPromoSpesial}
                      form={formPromoSpesial}
                      setLoading={setLoadingSubmit}
                    />
                  }
                />
                <Route
                  path="/publish/promo/detailspesial/:code"
                  element={
                    <FormPromoSpesial
                      edit={editPromoSpesial}
                      form={formPromoSpesial}
                      setLoading={setLoadingSubmit}
                    />
                  }
                />
                <Route
                  path="/publish/promo/tambahkupon"
                  element={
                    <FormPromoKupon
                      edit={editPromoKupon}
                      form={formPromoKupon}
                      setLoading={setLoadingSubmit}
                    />
                  }
                />
                <Route
                  path="/publish/promo/detailkupon/:code"
                  element={
                    <FormPromoKupon
                      edit={editPromoKupon}
                      form={formPromoKupon}
                      setLoading={setLoadingSubmit}
                    />
                  }
                />
                <Route
                  path="/publish/informasi/tambahpemberitahuan"
                  element={
                    <FormInformasiPemberitahuan
                      edit={editInformasiPemberitahuan}
                      form={formInformasiPemberitahuan}
                      setLoading={setLoadingSubmit}
                    />
                  }
                />
                <Route
                  path="/publish/informasi/detailpemberitahuan/:code"
                  element={
                    <FormInformasiPemberitahuan
                      edit={editInformasiPemberitahuan}
                      form={formInformasiPemberitahuan}
                      setLoading={setLoadingSubmit}
                    />
                  }
                />
                <Route
                  path="/publish/informasi/tambahmenarik"
                  element={
                    <FormInformasiMenarik
                      edit={editInformasiMenarik}
                      form={formInformasiMenarik}
                      setLoading={setLoadingSubmit}
                    />
                  }
                />
                <Route
                  path="/publish/informasi/tambahsplashscreen"
                  element={
                    <FormSplashScreen
                      edit={editSplashScreen}
                      form={formSplashScreen}
                      setLoading={setLoadingSubmit}
                    />
                  }
                />
                <Route
                  path="/publish/informasi/detailsplashscreen/:code"
                  element={
                    <FormSplashScreen
                      edit={editSplashScreen}
                      form={formSplashScreen}
                      setLoading={setLoadingSubmit}
                    />
                  }
                />
                <Route
                  path="/publish/informasi/detailmenarik/:code"
                  element={
                    <FormInformasiMenarik
                      edit={editInformasiMenarik}
                      form={formInformasiMenarik}
                      setLoading={setLoadingSubmit}
                    />
                  }
                />
                <Route
                  path="/publish/informasi/tambahproduk"
                  element={
                    <FormInformasiProduk
                      form={formInformasiProduk}
                      onFinish={handleFinishInformasiProduk}
                      onFinishFailed={onFinishFailed}
                      reset={reset}
                      setReset={setReset}
                      uploadImage={uploadImage}
                      setUploadImage={setUploadImage}
                    />
                  }
                />
                <Route
                  path="/publish/informasi/detailproduk/:code"
                  element={
                    <FormInformasiProduk
                      edit={editInformasiProduk}
                      form={formInformasiProduk}
                      onFinish={handleFinishInformasiProduk}
                      onFinishFailed={onFinishFailed}
                      reset={reset}
                      setReset={setReset}
                      uploadImage={uploadImage}
                      setUploadImage={setUploadImage}
                    />
                  }
                />
                <Route
                  path="/pelanggan/detailpelanggan/*"
                  element={<DetailPelanggan />}
                />
                {/* <Route
                path="/pelanggan/detailpelanggan/transaksi/produk/:code"
                element={<DetailPelanggan />}
              />
              <Route
                path="/pelanggan/detailpelanggan/perangkat/list/:code"
                element={<DetailPelanggan />}
              /> */}
                <Route
                  path="/pelanggan/transaksi/detailproduk/:code"
                  element={<DetailProdukPelanggan />}
                />
                <Route
                  path="/pelanggan/transaksi/detaildeposit/:code"
                  element={<DetailDeposit setTotalDeposit={setTotalDeposit} />}
                />
                <Route
                  path="/pelanggan/transaksi/detailpengecekan/:code"
                  element={<DetailPengecekan />}
                />
                <Route
                  path="/pelanggan/detailfeedback/:code"
                  element={<DetailFeedback />}
                />
                <Route
                  path="/pelanggan/tambahblacklist"
                  element={
                    <FormBlacklist
                      form={formTambahBlacklist}
                      setLoading={setLoadingSubmit}
                    />
                  }
                />
                <Route
                  path="/pelanggan/transaksi/salahtransfer/:code"
                  element={
                    <SalahTransfer
                      form={formSalahTransfer}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/integrasi/setotp/tambahprovider"
                  element={<FormSetOTP form={formIntegrasiOTP} />}
                />
                <Route
                  path="/pengguna/tambah_pengguna"
                  element={
                    <FormTambahPengguna
                      form={formTambahPengguna}
                      setLoading={setLoadingSubmit}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/pengguna/detail_pengguna/:id_pengguna"
                  element={
                    <FormTambahPengguna
                      edit={true}
                      form={formTambahPengguna}
                      setLoading={setLoadingSubmit}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/pengguna/tambah_jabatan"
                  element={
                    <TambahJabatan
                      form={formJabatan}
                      setLoading={setLoadingSubmit}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/pengguna/custom_jabatan/:id_role"
                  element={
                    <TambahJabatan
                      custom={true}
                      form={formJabatan}
                      setLoading={setLoadingSubmit}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/pengguna/detail_jabatan/:id_role"
                  element={
                    <TambahJabatan
                      edit={true}
                      form={formJabatan}
                      setLoading={setLoadingSubmit}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/pengguna/tambah_parent"
                  element={
                    <FormTambahParent
                      form={formParent}
                      setLoading={setLoadingSubmit}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/pengguna/detail_parent/:parent_code"
                  element={
                    <FormTambahParent
                      edit={true}
                      form={formParent}
                      setLoading={setLoadingSubmit}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/pengguna/tambah_menu"
                  element={
                    <FormTambahMenu
                      form={formMenu}
                      setLoading={setLoadingSubmit}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/pengguna/detail_menu/:code_menu"
                  element={
                    <FormTambahMenu
                      edit={true}
                      form={formMenu}
                      setLoading={setLoadingSubmit}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/pengguna/tambah_fitur"
                  element={
                    <FormTambahFitur
                      form={formFitur}
                      setLoading={setLoadingSubmit}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
                <Route
                  path="/pengguna/detail_fitur/:code_fitur"
                  element={
                    <FormTambahFitur
                      edit={true}
                      form={formFitur}
                      setLoading={setLoadingSubmit}
                      reset={reset}
                      setReset={setReset}
                    />
                  }
                />
              </Routes>
            </Suspense>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default FormPage;
